// export const ReworkUrl = (url) => typeof url === 'string' && url.startsWith("https://kruiz.online")
//   ? url?.replace("https://kruiz.online", '')
//   : url?.replace("https://sea.z.tech", '');

export const ReworkUrl = (url) => {
  if (typeof url !== 'string') return null

  const isProd = url.startsWith("https://kruiz.online")

  if (isProd) return url

  if (url.startsWith('/')) return `https://kruiz.online${url}`

  return url.replace("https://sea.z.tech", '');
}
