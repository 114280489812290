import React from 'react';
import cc from 'classcat';
import styles from './image.module.sass';

export const Image = ({
  src,
  srcSet,
  alt,
  width,
  height,
  size,
  media = '500px',
  ...props
}) => (
  <picture>
    {srcSet && <source srcSet={`${srcSet}`} type="image/webp" />}
    <img
      src={`${src}`}
      alt={alt}
      className={cc([styles.root, styles[size]])}
      width={size ? '100%' : `${width}px`}
      height={size ? '100%' : `${height}px`}
      {...props}
    />
  </picture>
);

Image.defaultProps = {
  src: '',
  srcSet: '',
  alt: '',
  width: 300,
  height: 300,
  size: 'cover',
};
