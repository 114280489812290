import React from 'react';
import cc from 'classcat';
import styles from './cruiseroute.module.sass';
import { declOfNum } from '../../utils/declOfNum';

// [**, *, *, *, *, *, *, *, **]

const makeBetweenBold = items =>
  items.map((i, index, { length }) => {
    // Добавляем первому и последнему элементу жирность
    if (index === 0 || index === length - 1) {
      return <span className={styles.bold}>{i}</span>;
    }
    return i;
  });

const makeSimpleList = items =>
  items
    .split(' – ')
    .map((i, index, { length }) => {
      if (index === 0 || index === length - 1) {
        return <span className={styles.bold}>{i}</span>;
      }
      return <span>{i}</span>;
    })
    .reduce((prev, curr) => [prev, ' – ', curr]);

export const CruiseRoute = ({
  className,
  items = [],
  noReturn = false,
  separator = ' – ',
  limit,
  boldBetween = true,
  onShowMore,
  simpleView = false,
}) => {
  if (items.length < 1) return null;

  const generateList = () => {
    const arr = items.split(separator);
    const { length } = arr;

    const res = boldBetween ? makeBetweenBold(arr) : arr;

    if (limit && onShowMore && length - 2 - 1 > limit) {
      const build = [];

      const betweenBoldValues = res.slice(1, res.length - 2);
      const limited = betweenBoldValues.slice(0, limit);
      const directionCount = betweenBoldValues.length - limit + 1;

      build.push(res[0], ...limited);
      build.push(
        <span
          role="button"
          tabIndex="0"
          className={styles.btn}
          onClick={onShowMore}
          onKeyDown={onShowMore}
        >
          Показать еще {directionCount}{' '}
          {declOfNum(directionCount, [
            'направление',
            'направления',
            'направлений',
          ])}
        </span>,
      );
      build.push(res[length - 1]);

      return build;
    }

    return res;
  };

  return (
    <p className={cc({ [className]: !!className })}>
      {simpleView
        ? makeSimpleList(items)
        : generateList().reduce((prev, curr) => [prev, separator, curr])}
      {noReturn && (
        <span className={styles.noReturn}>&nbsp;(без возвращения)</span>
      )}
    </p>
  );
};
