import React from 'react';
import cc from 'classcat';
import styles from './traveltime.module.sass';
// import { IconSVG } from '../SpriteSVG';
// import { Icon } from '../Icon';
// import { YaAndGaCruisePage } from '../../lib/YaAndGaHelper';

export const TravelTime = ({
  start,
  end,
  days,
  nights,
  className,
  size,
  startTime,
  endTime,
  // url,
  // ownerId,
  isMoscow,
}) => {
  const formatDays = `${days} дн.`;
  const formatNights = `${nights ?? days - 1} нч.`;
  return (
    <div
      className={cc([
        styles.wrapper,
        styles[size],
        { [className]: !!className },
      ])}
    >
      <div className={styles.dates}>
        <div>
          <div>{start}</div>
          <div>
            {startTime && startTime !== '00:00' && (
              <div className={styles.time}>
                {startTime}
                {!isMoscow && ' (местное)'}
              </div>
            )}
          </div>
        </div>
        -
        <div>
          <div>{`${end}`}</div>
          <div>
            {endTime && endTime !== '00:00' && (
              <div className={styles.time}>
                {endTime}
                {!isMoscow && ' (местное)'}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.days}>
        {days && formatDays} / {(nights || days) && formatNights}
      </div>
      {/*  {ownerId === 3 && (
        <div>
          <a
            href={`/ships/${url}#filter`}
            target={'_blank'}
            rel="noreferrer"
            onClick={() => YaAndGaCruisePage('click_on_part_to_change_date')}
            onMouseEnter={() =>
              YaAndGaCruisePage('hover_on_part_to_change_date')
            }
          >
            <Icon
              title={'Выбрать другую дату'}
              leftGap={8}
              icon={
                <IconSVG name={'common/EmptyDate'} width={24} height={24} />
              }
            />
          </a>
        </div>
      )} */}
    </div>
  );
};

TravelTime.defaultProps = {
  className: null,
  nights: null,
  size: 'default',
};
