import { useCallback, useEffect, useRef, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';

export const useEmblaSlider = (
  options = { loop: false, skipSnaps: false },
  isAutoplay = false,
) => {
  const autoplay = useRef(
    Autoplay({ delay: 5000 }, emblaRoot => emblaRoot.parentElement),
  );
  const [current, setCurrent] = useState(0);
  const [ref, embla] = useEmblaCarousel(
    options,
    isAutoplay ? [autoplay.current] : undefined,
  );

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setCurrent(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, current]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  const onSelectSlide = index => {
    embla.scrollTo(index);
  };

  return {
    ref,
    current,
    onSelectSlide,
    scrollPrev,
    scrollNext,
    prevBtnEnabled,
    nextBtnEnabled,
  };
};
